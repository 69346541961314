var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"bodytitle"},[_c('div',{staticClass:"title flexy flexsb"},[_c('img',{staticClass:"imglog wow lightSpeedIn",attrs:{"src":require("../../common/image/logo.png"),"alt":""},on:{"click":function($event){return _vm.goto({ url: '/', id: 1, name: '首页' })}}}),_c('div',{staticClass:"flexy flexsb"},_vm._l((_vm.list),function(i,k){return _c('div',{key:k,class:[
            'name flexcont wow  lightSpeedIn',
            _vm.id == i.id ? 'actvie' : '',
          ],on:{"click":function($event){return _vm.goto(i)}}},[_vm._v(" "+_vm._s(i.name)+" "),(k == 4)?_c('img',{staticStyle:{"margin-left":"10px"},attrs:{"src":require(`../../common/image/${
                _vm.flag ? 'xailatubiao' : '下拉'
              }.png`),"alt":""}}):_vm._e(),(k == 4 && _vm.flag)?_c('div',{staticClass:"xiangjiaruko"},[_vm._m(0,true)]):_vm._e()])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flexcont online"},[_c('a',{staticStyle:{"color":"#333333"},attrs:{"target":"_blank","href":"https://m.hqsmtech.com/hqboss-onlineshop/"}},[_vm._v("线上商家入驻")])])
}]

export { render, staticRenderFns }