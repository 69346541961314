<template>
  <div style="display: contents" v-if="bottom" class="bot">
    <div class="bottom">
      <div class="bottom-main flexsb">
        <div class="left">
          <img src="../../common/image/logogggg.png" alt="" /><br />
          <a href=""
            >Copyright ©2017-2024 深圳华麒商盟科技有限公司. All rights
            reserved.</a
          ><br />
          
          <div style="padding:20px 0;display: flex;align-items: center;">
            <a style="display:inline-block;text-decoration:none;height:20px;line-height:20px;margin-right: 10px;" href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" rel="">备案号：粤ICP备18092594号-1</a>
          		 		<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030602007968" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="../../common/image/batb.png" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">粤公网安备 44030602007968号</p></a>
          		 	</div>
        </div>
        <div class="right">
          <img src="../../common/image/gzh.png" alt="" />
          <p class="title">华麒商盟公众号</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "",
  data() {
    return {};
  },
  create() {},
  wacth: {},
  methods: {},
  computed: {
    ...mapState(["bottom"]),
  },
};
</script>
<style lang="scss" scoped>
.bottom {
  min-height: 162px;
  min-width: 1200px;
  background-color: #000000;
  .bottom-main {
    width: 1200px;
    margin: 0 auto;
    .left {
      padding-top: 38px;
      img{
        margin-bottom: 10px;
      }
      a {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #d6d6d6;
        text-decoration: none;
      }
    }
    .right {
      padding-top: 26px;
      text-align: center;
      .title {
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fffefe;
        margin-top: 10px;
      }
    }
  }
}
</style>
